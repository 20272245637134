import React from 'react';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Header, HeaderInner, HeaderBg, HeaderBody, HeaderPretitle, HeaderTitle, HeaderSubtitle, HeaderText, HeaderBtns } from 'src/interface/basics/headers';
import { BtnLink, BtnNextLink, NextLink } from 'src/interface/basics/links';
import ShapesAnimation from 'src/interface/animations/shapes-animation';
import { GDesk } from 'src/assets';


const WorkHeader = () => {
  return (
    <Header className='py-5 gradient-primary-accent'>
      <HeaderInner className='mvh-40'>
        <Container className='z-20' fluid='md'>
          <Row>
            <Col lg={8}>
              <HeaderBody className='animate fade-in-up delay-200'>
                <HeaderPretitle>Our Work</HeaderPretitle>
                <HeaderTitle>For the road ahead</HeaderTitle>
                <HeaderText>
                  We know technology can seem overwhelming.
                  That's why we build partnerships founded on transparency, collaboration, and results.
                  We find that's the best way to do great work.
                </HeaderText>
                <HeaderBtns className='animate fade-in-up delay-400'>
                  <BtnNextLink to='/contact' className='btn btn-opaque-white btn-lg'>Contact Us</BtnNextLink>
                </HeaderBtns>
              </HeaderBody>
            </Col>
          </Row>
        </Container>
      </HeaderInner>
      <ShapesAnimation />
    </Header>
  )
}

export default WorkHeader;
