import React, { useState } from 'react';
import { DmcCover } from 'src/assets';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Section, SectionHeader, SectionBody, SectionPretitle, SectionTitle, SectionSubtitle, SectionText, SectionBtns } from 'src/interface/basics/sections';
import { BtnNextLink, NextLink } from 'src/interface/basics/links';
import { LazyImg } from 'src/interface/vendors/lazyload-wrapper';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';

const WorkSection = ({ work, className = '' }) => {
  const [ animation, setAnimation ] = useState('');

  // Render
  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-right')}>
      <section className={`py-8 animate ${animation} ${className}`}>
        <Container>
          <Row className='flex-center'>
            <Col sm={12} md={{span: 6, order: 2}}>
              <div className='mb-5'>
                <LazyImg src={work.photo && work.photo.url} alt={work.title + ' cover photo'} />
              </div>
            </Col>
            <Col sm={12} md={{span: 6, order: 1}}>
              <SectionPretitle>Case Study</SectionPretitle>
              <SectionTitle>{work.title}</SectionTitle>
              <SectionSubtitle>{work.subtitle}</SectionSubtitle>
              <SectionText>{work.description}</SectionText>
              <SectionBtns>
                <BtnNextLink to={`/work/${work.slug}`}>Learn More</BtnNextLink>
              </SectionBtns>
            </Col>
          </Row>
        </Container>
      </section>
    </Waypoint>
  )
}

export default WorkSection;
