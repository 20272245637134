import React, { useEffect } from 'react';
import useSiteMetadata from 'src/hooks/work-hook';

import WorkHeader from 'src/components/work/work-header';
import WorkSection from 'src/components/work/work-section';

import MktRoute from 'src/interface/routes/mkt-route';
import MktNav from 'src/interface/navs/mkt-nav';
import MktPrefooter from 'src/interface/prefooters/mkt-prefooter';
import MktFooter from 'src/interface/footers/mkt-footer';

const WorkPage = () => {
  const works = useSiteMetadata();
  const formattedWorks = works.sort((a, b) => a.position - b.position);


  // Hooks
  useEffect(() => {
    console.log('Works: ', formattedWorks);
  }, [])


  // Render
  return (
    <MktRoute title='Mile Hi Labs | Our Work' description="We know technology can seem overwhelming. That's why we build partnerships founded on transparency, collaboration, and results.">
      <MktNav />
      <WorkHeader />
      <main className='mvh-50'>
        {formattedWorks.map((work, index) => (
          <WorkSection key={work.id} work={work} className={`py-8 ${[1,3,5,7].includes(index) ? 'bg-gray-50' :'bg-white'}`}/>
        ))}
        <MktPrefooter />
      </main>
      <MktFooter />
    </MktRoute>
  )
}

export default WorkPage;
